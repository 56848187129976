import { useState, useEffect } from 'react';
import { darkTheme } from "@thirdweb-dev/react";
import enoughImage from './styles/images/EH.gif';
import hollowImage from './styles/images/TH.gif';
import "./styles/Home.css";
import { useContract, Web3Button, useContractRead, useAddress } from '@thirdweb-dev/react';
import { ChainId } from '@thirdweb-dev/sdk';
import tokenContractAbi from './abis/tokenABI.json';  // ABI for the token contract
import spenderContractAbi from './abis/swapABI.json';  // ABI for the spender contract
import { BigNumber } from 'ethers';

// Setup provider and signer
export default function Home() {
  const tokenContractAddress = "0xD1B4367dd9f235f9ee61878019d66E31511E98eE";
  const spenderAddress = "0x5bC2fc6A8538e6913995741234c2512D3ae6ada7";
  

  // Using custom ABIs for each contract
  const { contract: tokenContract } = useContract(tokenContractAddress, "edition");
  const { contract: spenderContract } = useContract(spenderAddress, spenderContractAbi);

  const address = useAddress();
  
  const [isApproved, setIsApproved] = useState(false);
  const [balance0, setBalance0] = useState<number>(0);
  const [balance1, setBalance1] = useState<number>(0);
  
  const { data: isApprovedData} = useContractRead(
    tokenContract,   // Contract instance
    'isApprovedForAll', // Name of the function
    [tokenContractAddress, spenderAddress] // Arguments for the function
  );
  const fetchBalances = async () => {
    if (tokenContract && address) {
      try {
        const balance0Result = await tokenContract.call("balanceOf", [address, 0]);
        if (BigNumber.isBigNumber(balance0Result)) {
          setBalance0(balance0Result.toNumber());
        } else {
          console.warn("Balance 0 is not a BigNumber, setting to 0");
          setBalance0(0);
        }
      } catch (error) {
        console.error("Error fetching balance 0:", error);
        setBalance0(0);
      }

      try {
        const balance1Result = await tokenContract.call("balanceOf", [address, 1]);
        if (BigNumber.isBigNumber(balance1Result)) {
          setBalance1(balance1Result.toNumber());
        } else {
          console.warn("Balance 1 is not a BigNumber, setting to 0");
          setBalance1(0);
        }
      } catch (error) {
        console.error("Error fetching balance 1:", error);
        setBalance1(0);
      }
    }
  };

  useEffect(() => {
    fetchBalances();
  }, [tokenContract, address]);

  // Effect to handle the response
  useEffect(() => {
    if (isApprovedData !== undefined) {
      setIsApproved(isApprovedData);
    }
  }, [isApprovedData]);

  return (
    <main className="main bg1">
      <div className="colbox">
        <h2 className="fa subhead">Voidcaller</h2>
        <h1 className="fa head">The Hollow's Burn</h1>
      </div>
        <div className="colbox">
        <Web3Button
          className='w3button'
          style={{ boxShadow:"3px 3px 3px #000000",fontFamily: "Hacked, sans-serif", color: "white", textShadow: "0 0 2px #000000, 0 0 2px #000000, 0 0 2px #000000, 0 0 2px #000000", backgroundColor: "#051520" }} theme={darkTheme({ fontFamily: "Hacked, sans-serif", colors: { modalBg: "#002020", accentText: "green" } })}
          contractAddress={spenderAddress}
          action={async () => {
            if (tokenContract && spenderContract) {
              if (!isApproved) {
                console.log(`Approving spenderAddress: ${spenderAddress}`);
                await tokenContract.setApprovalForAll(spenderAddress, true);
                setIsApproved(true); // Update the state after approval
              } else {
                console.log(`Swapping tokens using spenderAddress: ${spenderAddress}`);
                await spenderContract.call("swapTokens");
                await fetchBalances();
              }
            } else {
              console.error("Contracts are not initialized");
            }
          }}
        >
          {isApproved ? 'Burn Token' : 'Approve'}
        </Web3Button>
        </div>
      <div className="rowboxDcolboxM">
        <div className="colbox">
          <h2 className="fa">Burn Enough</h2>
          <img src={enoughImage} alt='Enough' className="img"></img>
          <h3 className="fa">{balance0} Held</h3>
        </div>
        <div className="colbox">
        <h2 className="fa">Gain The Hollow</h2>
          <img src={hollowImage} alt='The Hollow' className="img"></img>
          <h3 className="fa">{balance1} Held</h3>
        </div>
        
      </div>
      <h5 className="fa marsides">“The Hollow” was born from the anger of watching those in positions of influence or power, continue to take advantage.<br></br>

      Watching as people were led away from their dreams. Watching as the world falls into disillusion. <br></br>

      “The Hollow” stands to represent the ones who have watched for too long. The one’s who scream for change, we hear your cries. <br></br>

      Watch the fall of false prophets; Embrace the Void.</h5>
    </main>
  );
}
